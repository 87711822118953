<template>
  <div>
    <!-- Breadcrumb -->
    <b-row
      class="content-header"
    >
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="content-header-title float-left pr-1 mb-0">
              Reports
            </h2>
            <div class="breadcrumb-wrapper">
              <b-breadcrumb>
                <b-breadcrumb-item to="/apps/reports/type">
                  <feather-icon
                    icon="HomeIcon"
                    size="16"
                    class="align-text-top"
                  />
                </b-breadcrumb-item>
                <b-breadcrumb-item
                  @click="goBack"
                >
                  Reports
                </b-breadcrumb-item>
                <b-breadcrumb-item
                  active
                >
                  Vehicle Log
                </b-breadcrumb-item>
              </b-breadcrumb>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-card v-if="reportData && clientOptions && ($can('read', 'reports') || $can('update', 'reports'))">
      <b-card-header>
        <h5>
          Report Details
        </h5>
      </b-card-header>
      <b-card-body>
        <!-- User Info: Input Fields -->
        <validation-observer
          ref="observer"
          #default="{ handleSubmit }"
        >
          <b-form
            @submit.prevent="handleSubmit(validateForm)"
          >
            <!-- Field: Standard Hidden Fields -->
            <input
              v-model="reportData.report_type"
              type="hidden"
              name="report_type"
            >
            <input
              v-model="reportData.time_zone"
              type="hidden"
              name="time_zone"
            >
            <input
              v-model="reportData.client_display_name"
              type="hidden"
              name="client_display_name"
            >
            <input
              v-model="reportData.attachment_list"
              type="hidden"
              name="attachment_list"
            >
            <!-- Field: report_id, report_date_local -->
            <b-row
              v-if="reportData.report_id"
            >
              <b-col
                md="6"
              >
                <b-form-group
                  label="Report ID"
                  label-for="report_id"
                >
                  {{ reportData.report_id }}
                </b-form-group>
              </b-col>
              <b-col
                md="6"
              >
                <b-form-group
                  label="Report Date"
                  label-for="report_date_local"
                >
                  {{ reportData.report_date_local }}
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: user_display_name -->
            <b-row
              v-if="reportData.report_id"
              class="mb-2"
            >
              <b-col
                md="6"
              >
                <b-form-group
                  label="Submitted By"
                  label-for="user_display_name"
                >
                  {{ reportData.user_display_name }}
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Client Selection -->
            <b-row>
              <b-col
                md="6"
              >
                <b-form-group
                  label="Client"
                  label-for="clientname"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="clientname"
                    rules="required"
                  >
                    <v-select
                      v-model="reportData.clientname"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="clientOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="clientname"
                      @input="handleSelectClient"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Site selection -->
            <b-row>
              <b-col
                md="6"
              >
                <b-form-group
                  label="Site"
                  label-for="sitename"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="sitename"
                    rules="required"
                  >
                    <v-select
                      v-model="reportData.sitename"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="siteOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="sitename"
                      @input="handleSelectSite"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Field: Site Address -->
              <b-col
                md="6"
              >
                <b-form-group
                  label="Site Address"
                  label-for="site_address"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="site_address"
                    rules="required"
                  >
                    <b-form-input
                      id="site_address"
                      v-model="reportData.site_address"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Date: -->
            <b-row>
              <b-col md="6">
                <b-form-group
                  label="Date:"
                  label-for="inspection_date"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="inspection_date"
                    rules="required"
                  >
                    <flat-pickr
                      id="inspection_date"
                      v-model="reportData.inspection_date"
                      class="form-control"
                      :config="{ enableTime: true,dateFormat: 'Y-m-d H:i:ss'}"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Licence Plate: -->
            <b-row class="mb-2">
              <b-col md="6">
                <b-form-group
                  label="Licence Plate:"
                  label-for="licence_plate"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="licence_plate"
                    rules="required"
                  >
                    <b-form-input
                      id="licence_plate"
                      v-model="reportData.licence_plate"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <!--  -->
              </b-col>
            </b-row>
            <!-- Field: Vehicle Type -->
            <b-row>
              <b-col
                md="6"
              >
                <b-form-group
                  label="Vehicle Type"
                  label-for="vehicle_type"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="vehicle_type"
                    rules="required"
                  >
                    <v-select
                      v-model="reportData.vehicle_type"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="vehicleTypeOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="vehicle_type"
                      @input="handleVehicleTypeSelection"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Vehicle Make Model -->
            <b-row class="mb-2">
              <b-col md="6">
                <b-form-group
                  label="Vehicle Make Model:"
                  label-for="make_model"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="make_model"
                    rules="required"
                  >
                    <b-form-input
                      id="make_model"
                      v-model="reportData.make_model"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <!--  -->
              </b-col>
            </b-row>
            <!-- Field: Vehicle Year: -->
            <b-row class="mb-2">
              <b-col md="6">
                <b-form-group
                  label="Vehicle Year:"
                  label-for="vehicle_year"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="vehicle_year"
                    rules="required"
                  >
                    <b-form-input
                      id="vehicle_year"
                      v-model="reportData.vehicle_year"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <!--  -->
              </b-col>
            </b-row>
            <!-- Field: Purchased Gas: -->
            <b-row
              v-if="reportData.vehicle_type === 'Gas'"
              class="mb-2"
            >
              <b-col md="6">
                <b-form-group
                  label="Purchased Gas:"
                  label-for="is_purchase_gas"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="is_purchase_gas"
                    rules="required"
                  >
                    <v-select
                      v-model="reportData.is_purchase_gas"
                      :options="yesNoOptions"
                      :reduce="val => val.value"
                      input-id="is_tow_called"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Purchase Gas Price$ / Litres: -->
            <b-row
              v-if="reportData.is_purchase_gas"
              class="mb-2"
            >
              <b-col md="6">
                <b-form-group
                  label="Purchase Gas Price$:"
                  label-for="gas_price"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="gas_price"
                  >
                    <b-form-input
                      id="gas_price"
                      v-model="reportData.gas_price"
                      type="number"
                      step="0.01"
                      min="0"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Purchase Gas Litres:"
                  label-for="gas_litres"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="gas_litres"
                  >
                    <b-form-input
                      id="gas_litres"
                      v-model="reportData.gas_litres"
                      type="number"
                      step="1"
                      min="0"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Purchased Electricity -->
            <b-row
              v-if="reportData.vehicle_type === 'EV'"
              class="mb-2"
            >
              <b-col md="6">
                <b-form-group
                  label="Purchased Electricity"
                  label-for="is_purchase_electricity"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="is_purchase_electricity"
                    rules="required"
                  >
                    <v-select
                      v-model="reportData.is_purchase_electricity"
                      :options="yesNoOptions"
                      :reduce="val => val.value"
                      input-id="is_purchase_electricity"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Charge Fee / Percent: -->
            <b-row
              v-if="reportData.is_purchase_electricity"
              class="mb-2"
            >
              <b-col md="6">
                <b-form-group
                  label="Charge Fee:"
                  label-for="charge_fee"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="charge_fee"
                  >
                    <b-form-input
                      id="charge_fee"
                      v-model="reportData.charge_fee"
                      type="number"
                      step="0.01"
                      min="0"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Charge Percent:"
                  label-for="charge_percent"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="charge_percent"
                  >
                    <b-form-input
                      id="charge_percent"
                      v-model="reportData.charge_percent"
                      type="number"
                      step="1"
                      min="0"
                      max="100"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Last Oil change: -->
            <b-row
              v-if="reportData.vehicle_type === 'Gas'"
              class="mb-2"
            >
              <b-col md="6">
                <b-form-group
                  label="Last Oil change:"
                  label-for="last_oil_change"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="last_oil_change"
                    rules="required"
                  >
                    <b-form-input
                      id="last_oil_change"
                      v-model="reportData.last_oil_change"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <!--  -->
              </b-col>
            </b-row>
            <!-- Field: Next Oil Change: -->
            <b-row
              v-if="reportData.vehicle_type === 'Gas'"
              class="mb-2"
            >
              <b-col md="6">
                <b-form-group
                  label="Next Oil Change:"
                  label-for="next_oil_change"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="next_oil_change"
                    rules="required"
                  >
                    <b-form-input
                      id="next_oil_change"
                      v-model="reportData.next_oil_change"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <!--  -->
              </b-col>
            </b-row>
            <!-- Field: Starting KM: -->
            <b-row class="mb-2">
              <b-col md="6">
                <b-form-group
                  label="Starting KM:"
                  label-for="starting_km"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="starting_km"
                    rules="required"
                  >
                    <b-form-input
                      id="starting_km"
                      v-model="reportData.starting_km"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <!--  -->
              </b-col>
            </b-row>
            <!-- Field: Ending KM: -->
            <b-row class="mb-2">
              <b-col md="6">
                <b-form-group
                  label="Ending KM:"
                  label-for="ending_km"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="ending_km"
                    rules="required"
                  >
                    <b-form-input
                      id="ending_km"
                      v-model="reportData.ending_km"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <!--  -->
              </b-col>
            </b-row>
            <!-- Field: Leaks: -->
            <b-row class="mb-2">
              <b-col md="6">
                <b-form-group
                  label="Leaks:"
                  label-for="leaks"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="leaks"
                  >
                    <b-form-input
                      id="leaks"
                      v-model="reportData.leaks"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <!--  -->
              </b-col>
            </b-row>
            <!-- Field: Dents/Scratches: -->
            <b-row class="mb-2">
              <b-col md="12">
                <b-form-group
                  label="Dents/Scratches:"
                  label-for="dents_scratches"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="dents_scratches"
                  >
                    <b-form-textarea
                      id="dents_scratches"
                      v-model="reportData.dents_scratches"
                      rows="3"
                      max-rows="8"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Tire Pressure/Tred: -->
            <b-row class="mb-2">
              <b-col md="6">
                <b-form-group
                  label="Tire Pressure/Tred:"
                  label-for="tire_pressure"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="tire_pressure"
                  >
                    <b-form-input
                      id="tire_pressure"
                      v-model="reportData.tire_pressure"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <!--  -->
              </b-col>
            </b-row>
            <!-- Field: Decals/Lights: -->
            <b-row class="mb-2">
              <b-col md="6">
                <b-form-group
                  label="Decals/Lights:"
                  label-for="decals_lights"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="decals_lights"
                  >
                    <b-form-input
                      id="decals_lights"
                      v-model="reportData.decals_lights"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <!--  -->
              </b-col>
            </b-row>
            <!-- Field: Upholstery (Floor, Seats, Doors, Dashboard, Roof): -->
            <b-row class="mb-2">
              <b-col md="12">
                <b-form-group
                  label="Upholstery (Floor, Seats, Doors, Dashboard, Roof):"
                  label-for="upholstery"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="upholstery"
                  >
                    <b-form-textarea
                      id="upholstery"
                      v-model="reportData.upholstery"
                      rows="3"
                      max-rows="8"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Seat belts (Working): -->
            <b-row class="mb-2">
              <b-col md="6">
                <b-form-group
                  label="Seat belts (Working):"
                  label-for="seatbelts"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="seatbelts"
                  >
                    <b-form-input
                      id="seatbelts"
                      v-model="reportData.seatbelts"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <!--  -->
              </b-col>
            </b-row>
            <!-- Field: Folding Seats (Working): -->
            <b-row class="mb-2">
              <b-col md="6">
                <b-form-group
                  label="Folding Seats (Working):"
                  label-for="folding_seats"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="folding_seats"
                  >
                    <b-form-input
                      id="folding_seats"
                      v-model="reportData.folding_seats"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <!--  -->
              </b-col>
            </b-row>
            <!-- Field: Headrests (Working): -->
            <b-row class="mb-2">
              <b-col md="6">
                <b-form-group
                  label="Headrests (Working):"
                  label-for="headrests"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="headrests"
                  >
                    <b-form-input
                      id="headrests"
                      v-model="reportData.headrests"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <!--  -->
              </b-col>
            </b-row>
            <!-- Field: Glowlights (Dashboard): -->
            <b-row class="mb-2">
              <b-col md="6">
                <b-form-group
                  label="Glowlights (Dashboard):"
                  label-for="glow_lights"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="glow_lights"
                  >
                    <b-form-input
                      id="glow_lights"
                      v-model="reportData.glow_lights"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <!--  -->
              </b-col>
            </b-row>
            <!-- Field: Interior Lights: -->
            <b-row class="mb-2">
              <b-col md="6">
                <b-form-group
                  label="Interior Lights:"
                  label-for="interior_lights"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="interior_lights"
                  >
                    <b-form-input
                      id="interior_lights"
                      v-model="reportData.interior_lights"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <!--  -->
              </b-col>
            </b-row>
            <!-- Field: Radio / Volume (All Outputs): -->
            <b-row class="mb-2">
              <b-col md="6">
                <b-form-group
                  label="Radio / Volume (All Outputs):"
                  label-for="radio"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="radio"
                  >
                    <b-form-input
                      id="radio"
                      v-model="reportData.radio"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <!--  -->
              </b-col>
            </b-row>
            <!-- Field: Window (Driver controls / Passenger controls): -->
            <b-row class="mb-2">
              <b-col md="6">
                <b-form-group
                  label="Window (Driver controls / Passenger controls):"
                  label-for="windows"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="windows"
                  >
                    <b-form-input
                      id="windows"
                      v-model="reportData.windows"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <!--  -->
              </b-col>
            </b-row>
            <!-- Field: Mirrors: -->
            <b-row class="mb-2">
              <b-col md="6">
                <b-form-group
                  label="Mirrors:"
                  label-for="mirrors"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="mirrors"
                  >
                    <b-form-input
                      id="mirrors"
                      v-model="reportData.mirrors"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <!--  -->
              </b-col>
            </b-row>
            <!-- Field: Air Controls: -->
            <b-row class="mb-2">
              <b-col md="6">
                <b-form-group
                  label="Air Controls:"
                  label-for="air_controls"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="air_controls"
                  >
                    <b-form-input
                      id="air_controls"
                      v-model="reportData.air_controls"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <!--  -->
              </b-col>
            </b-row>
            <!-- Field: Wipers: -->
            <b-row class="mb-2">
              <b-col md="6">
                <b-form-group
                  label="Wipers:"
                  label-for="wipers"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="wipers"
                  >
                    <b-form-input
                      id="wipers"
                      v-model="reportData.wipers"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <!--  -->
              </b-col>
            </b-row>
            <!-- Field: Lights / High-beams / Signals / Brakes: -->
            <b-row class="mb-2">
              <b-col md="6">
                <b-form-group
                  label="Lights / High-beams / Signals / Brakes:"
                  label-for="signal_lights"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="signal_lights"
                  >
                    <b-form-input
                      id="signal_lights"
                      v-model="reportData.signal_lights"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <!--  -->
              </b-col>
            </b-row>
            <!-- Field: Electric Seats (All Positions): -->
            <b-row class="mb-2">
              <b-col md="6">
                <b-form-group
                  label="Electric Seats (All Positions):"
                  label-for="electric_seats"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="electric_seats"
                  >
                    <b-form-input
                      id="electric_seats"
                      v-model="reportData.electric_seats"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <!--  -->
              </b-col>
            </b-row>
            <!-- Field: Locks (Driver / Individual): -->
            <b-row class="mb-2">
              <b-col md="6">
                <b-form-group
                  label="Locks (Driver / Individual):"
                  label-for="door_locks"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="door_locks"
                  >
                    <b-form-input
                      id="door_locks"
                      v-model="reportData.door_locks"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <!--  -->
              </b-col>
            </b-row>
            <!-- Field: Horn: -->
            <b-row class="mb-2">
              <b-col md="6">
                <b-form-group
                  label="Horn:"
                  label-for="horn"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="horn"
                  >
                    <b-form-input
                      id="horn"
                      v-model="reportData.horn"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <!--  -->
              </b-col>
            </b-row>
            <!-- Field: Fluids (Windshield, Power steering, Oil, Brake fluid): -->
            <b-row class="mb-2">
              <b-col md="6">
                <b-form-group
                  label="Fluids (Windshield, Power steering, Oil, Brake fluid):"
                  label-for="fluids"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="fluids"
                  >
                    <b-form-input
                      id="fluids"
                      v-model="reportData.fluids"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <!--  -->
              </b-col>
            </b-row>
            <!-- Field: Belts: -->
            <b-row class="mb-2">
              <b-col md="6">
                <b-form-group
                  label="Belts:"
                  label-for="belts"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="belts"
                  >
                    <b-form-input
                      id="belts"
                      v-model="reportData.belts"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <!--  -->
              </b-col>
            </b-row>
            <!-- Field: Filters: -->
            <b-row class="mb-2">
              <b-col md="6">
                <b-form-group
                  label="Filters"
                  label-for="filters"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="filters"
                  >
                    <b-form-input
                      id="filters"
                      v-model="reportData.filters"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <!--  -->
              </b-col>
            </b-row>
            <!-- Field: Battery Terminals: -->
            <b-row class="mb-2">
              <b-col md="6">
                <b-form-group
                  label="Battery Terminals:"
                  label-for="battery"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="battery"
                  >
                    <b-form-input
                      id="battery"
                      v-model="reportData.battery"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <!--  -->
              </b-col>
            </b-row>
            <!-- Field: Notes: -->
            <b-row class="mb-2">
              <b-col md="12">
                <b-form-group
                  label="Notes:"
                  label-for="notes"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="notes"
                  >
                    <b-form-textarea
                      id="notes"
                      v-model="reportData.notes"
                      rows="3"
                      max-rows="8"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Submit Button -->
            <div
              v-if="reportID === null"
            >
              <b-row class="mt-3">
                <b-col
                  v-if="$can('create', 'reports')"
                  md="2"
                >
                  <b-button
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    block
                    :disabled="preventDoubleClick"
                    type="submit"
                  >
                    Add Report
                  </b-button>
                </b-col>
              </b-row>
            </div>
            <!-- Print Button -->
            <div
              v-if="reportID !== null"
            >
              <b-row class="mt-3">
                <b-col
                  v-if="$can('update', 'reports')"
                  md="2"
                >
                  <b-button
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    block
                    :disabled="preventDoubleClick"
                    type="submit"
                  >
                    Save Changes
                  </b-button>
                </b-col>
                <b-col
                  v-if="$can('read', 'reports')"
                  md="2"
                >
                  <!-- Print Button -->
                  <b-button
                    variant="secondary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    block
                    :disabled="preventDoubleClick"
                    @click="printReport"
                  >
                    Print Report
                  </b-button>
                </b-col>
              </b-row>
              <b-row
                v-if="$can('delete', 'reports')"
                class="mt-4"
              >
                <b-col
                  v-if="!reportData.approved"
                  md="2"
                >
                  <!-- Approve Button -->
                  <b-button
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    block
                    :disabled="preventDoubleClick"
                    @click="handleApprovalToggle"
                  >
                    Approve
                  </b-button>
                </b-col>
                <b-col
                  v-else
                  md="2"
                >
                  <!-- Approve Button -->
                  <b-button
                    variant="secondary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    block
                    :disabled="preventDoubleClick"
                    @click="handleApprovalToggle"
                  >
                    Unapprove
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </b-form>
        </validation-observer>
      </b-card-body>
    </b-card>
    <!-- Photos and Images -->
    <b-card v-if="reportData && reportID !== null && ($can('read', 'reports') || $can('update', 'reports'))">
      <b-card-header>
        <h5>
          Photos / Images
        </h5>
      </b-card-header>
      <b-card-body>
        <!-- Button to trigger the file input -->
        <div>
          <b-row class="mb-2">
            <b-col
              v-if="reportData && clientOptions && $can('update', abilityRequired) && reportData.username === userData.username"
              md="2"
            >
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                block
                :disabled="preventDoubleClick"
                @click="openCamera"
              >
                Take Picture
              </b-button>
            </b-col>
            <b-col
              v-if="reportData && clientOptions && $can('update', abilityRequired) && reportData.username === userData.username"
              md="2"
            >
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                block
                :disabled="preventDoubleClick"
                @click="openImageSelector"
              >
                Add Image
              </b-button>
            </b-col>
          </b-row>
        </div>
        <!-- Images List -->
        <section
          v-if="reportData.attachment_list"
          class="grid-view"
        >
          <b-card
            v-for="mediaItem in reportData.attachment_list"
            :id="mediaItem.filename"
            :ref="mediaItem.filename"
            :key="mediaItem.filename"
            no-body
          >
            <div
              v-if="mediaItem.filename.slice(-3) === 'jpg'"
              class="item-img text-center"
            >
              <thumbs-lazy-image-component
                class="card-img-top"
                :src="mediaItem.access_url"
                src-placeholder="@/assets/images/noImage.png"
              />
            </div>

            <b-card-footer
              v-if="mediaItem.filename.slice(-3) === 'jpg' && $can('update', abilityRequired) && reportData.username === userData.username"
              class="pt-1 pb-1 border-dark"
            >
              <b-row>
                <b-col>
                  <b-button
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    block
                    :disabled="preventDoubleClick"
                    @click="deleteImage(mediaItem)"
                  >
                    Delete Image
                  </b-button>
                </b-col>
              </b-row>
            </b-card-footer>
          </b-card>
        </section>
        <!-- Hidden file input for accessing camera -->
        <input
          ref="cameraInput"
          type="file"
          accept="image/*"
          capture="environment"
          style="display: none;"
          @change="handleImageChange"
        >
        <input
          ref="imageInput"
          type="file"
          accept="image/*"
          style="display: none;"
          @change="handleImageChange"
        >
      </b-card-body>
    </b-card>
    <!-- Files -->
    <b-card v-if="reportData && reportID !== null && ($can('read', 'reports') || $can('update', 'reports'))">
      <b-card-header>
        <h5>
          Files
        </h5>
      </b-card-header>
      <b-card-body>
        <!-- Button to trigger the file input -->
        <div>
          <b-row class="mb-2">
            <b-col
              v-if="reportData && clientOptions && $can('update', abilityRequired) && reportData.username === userData.username"
              md="2"
            >
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                block
                :disabled="preventDoubleClick"
                @click="openFileSelector"
              >
                Add File
              </b-button>
            </b-col>
          </b-row>
        </div>
        <!-- File List -->
        <section
          v-if="reportData.attachment_list"
          class="grid-view"
        >
          <b-card
            v-for="mediaItem in reportData.attachment_list"
            :id="mediaItem.filename"
            :ref="mediaItem.filename"
            :key="mediaItem.filename"
            no-body
          >
            <b-card-footer
              v-if="mediaItem.filename.slice(-3) !== 'jpg'"
              class="pt-1 pb-1 border-dark"
            >
              <b-row>
                <b-col>
                  <b-button
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    block
                    :disabled="preventDoubleClick"
                    @click="downloadFile(mediaItem)"
                  >
                    Download File
                  </b-button>
                </b-col>
                <b-col
                  v-if="reportData && clientOptions && $can('update', abilityRequired) && reportData.username === userData.username"
                >
                  <b-button
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    block
                    :disabled="preventDoubleClick"
                    @click="deleteFile(mediaItem)"
                  >
                    Delete File
                  </b-button>
                </b-col>
              </b-row>
            </b-card-footer>
          </b-card>
        </section>
        <!-- Hidden file input for accessing camera -->
        <input
          ref="fileInput"
          type="file"
          accept="*/*"
          style="display: none;"
          @change="handleFileChange"
        >
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import { Storage, Auth } from 'aws-amplify'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router'
import { required, email } from '@validations'
import {
  BBreadcrumb, BBreadcrumbItem, BCard, BCardHeader, BCardBody, BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BFormTextarea, BCardFooter,
} from 'bootstrap-vue'
import { Storage, Auth } from 'aws-amplify'
import { ref, onUnmounted } from '@vue/composition-api'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import reportsStoreModule from '../reportsStoreModule'
import clientStoreModule from '../../clients/clientStoreModule'
import ThumbsLazyImageComponent from '../thumbsLazyImageComponent'

// Notification
// import { useToast } from 'vue-toastification/composition'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// import ImageUploader from 'vue-image-upload-resize'
import ability from '../../../../libs/acl/ability'

export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    BFormTextarea,
    ThumbsLazyImageComponent,
    BCardFooter,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
      preventDoubleClick: false,
    }
  },
  setup() {
    let theReportID = null
    let theSK = null
    let theReportType = null
    if (router.currentRoute.params.reportID !== undefined && router.currentRoute.params.reportID !== null
      && router.currentRoute.params.SK !== undefined && router.currentRoute.params.SK !== null
      && router.currentRoute.params.report_type !== undefined && router.currentRoute.params.report_type !== null) {
      theReportID = router.currentRoute.params.reportID
      theSK = router.currentRoute.params.SK
      theReportType = router.currentRoute.params.report_type
    }
    const reportID = ref(theReportID)
    const SK = ref(theSK)
    const reportType = ref(theReportType)
    const reportData = ref(null)
    const clientsData = ref([])
    // TODO: Get a list of clients available to the current user
    const clientOptions = ref([])
    const sitesData = ref([])
    const siteOptions = ref([])
    // ********** Import options from settings **********
    const yesNoOptions = store.state.appSettings.yes_no_options
    const vehicleTypeOptions = store.state.appSettings.vehicle_type_options

    const REPORTS_STORE_MODULE_NAME = 'app-reports'
    // Register module
    if (!store.hasModule(REPORTS_STORE_MODULE_NAME)) store.registerModule(REPORTS_STORE_MODULE_NAME, reportsStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REPORTS_STORE_MODULE_NAME)) store.unregisterModule(REPORTS_STORE_MODULE_NAME)
    })

    const CLIENT_APP_STORE_MODULE_NAME = 'app-client'
    // Register module
    if (!store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.registerModule(CLIENT_APP_STORE_MODULE_NAME, clientStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.unregisterModule(CLIENT_APP_STORE_MODULE_NAME)
    })

    const toast = useToast()
    if (ability.can('read', 'clients')) {
      toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Getting Client List',
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
      const qvars = {
        forceRefresh: false,
        q: null,
        perPage: 0,
        page: 1,
        sortBy: 'display_name',
        sortDesc: true,
        status: null,
        service: null,
      }
      console.log(qvars)
      store
        .dispatch('app-client/fetchClients', qvars)
        .then(response => {
          clientsData.value = response.data
          clientsData.value.clients.forEach(item => {
            clientOptions.value.push({ label: item.display_name, value: item.clientname })
          })
          console.log('clientsData')
          console.log(clientsData)
          console.log('clientOptions')
          console.log(clientOptions)
        })
        .catch(error => {
          console.log(error) // No current user
          if (typeof error.response !== 'undefined' && error.response.status === 404) {
            clientsData.value = undefined
          }
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching clients list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    function getClientSites(clientname) {
      console.log(clientname)
      console.log('fetchClientSites')
      toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Getting Site List',
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
      if (ability.can('read', 'clients')) {
        const qvars = {
          forceRefresh: false,
          q: null,
          perPage: 0,
          page: 1,
          sortBy: 'sitename',
          sortDesc: true,
          status: null,
          clientname,
        }
        console.log(qvars)
        store
          .dispatch('app-client/fetchClientSites', qvars)
          .then(response => {
            console.log(response)
            console.log(sitesData.value)
            sitesData.value = response.data.clientSites
            siteOptions.value = sitesData.value
            sitesData.value.forEach((item, index) => {
              if (item.is_active) {
                siteOptions.value[index].label = item.sitename
                siteOptions.value[index].value = item.sitename
              }
            })
            siteOptions.value.unshift({ label: '<select>', value: '' })
            console.log('this.sitesData')
            console.log(sitesData)
            console.log('this.siteOptions')
            console.log(siteOptions)
          })
          .catch(error => {
            console.log(error)
            store.state.clients.fetchingClientSitesList = false
            if (typeof error.response !== 'undefined' && error.response.status === 404) {
              sitesData.value = []
            }
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error fetching Client Sites list',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    }

    return {
      reportID,
      SK,
      reportType,
      reportData,
      clientsData,
      clientOptions,
      siteOptions,
      sitesData,
      yesNoOptions,
      vehicleTypeOptions,
      getClientSites,
    }
  },
  created() {
    if (this.reportID === null) {
      this.reportData = {}
      this.reportData.report_type = 'vehicle_log'
      this.reportData.time_zone = new Intl.DateTimeFormat().resolvedOptions().timeZone
      this.reportData.client_display_name = ''
      this.reportData.attachment_list = []
      this.reportData.sitename = ''
      this.reportData.is_purchase_gas = false
      this.reportData.is_purchase_electricity = false
    } else {
      this.getExistingReport()
    }
  },
  methods: {
    goBack() {
      console.log('goback')
      window.history.back()
    },
    handleSelectClient(val) {
      console.log('handleSelectClient')
      this.clientsData.clients.forEach(item => {
        if (item.clientname === val) {
          this.reportData.client_display_name = item.display_name
        }
      })
      // clear the site selection and sites list
      this.reportData.site_address = null
      this.reportData.sitename = null
      this.sitesData = []
      this.siteOptions = []
      // Get a list of sites for the selected client
      this.getClientSites(val)
    },
    handleSelectSite(val) {
      console.log(val)
      this.sitesData.forEach(item => {
        if (item.sitename === val) {
          this.reportData.site_address = `${item.address}, ${item.province}, ${item.country}`
        }
      })
    },
    handleVehicleTypeSelection(val) {
      console.log(val)
      console.log(this.reportData.vehicle_type)
    },
    isNumeric(str) {
      if (typeof str !== 'string') return false // we only process strings!
      return !Number.isNaN(str) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            && !Number.isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    },
    getExistingReport() {
      console.log('getExistingReport')
      if (this.reportID !== null && this.SK !== null && this.reportType !== null && ability.can('read', 'reports')) {
        console.log('getting report data')
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Getting Report Data',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        const qvars = {
          reportID: this.reportID,
          SK: this.SK,
          reportType: this.reportType,
        }
        store.dispatch('app-reports/fetchReport', qvars)
          .then(response => {
            console.log(response)
            this.reportData = response.data
          })
          .catch(error => {
            console.log(error)
            let theErrorStr = 'The following error(s) where received:\n'
            if (typeof error.response.data.errors !== 'undefined') {
              error.response.data.errors.forEach(item => {
                theErrorStr += `${item.message}\n`
              })
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error Getting Report.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: theErrorStr,
              },
            })
          })
      }
    },
    validateForm() {
      console.log('validateForm')
      if (this.preventDoubleClick === false) {
        this.preventDoubleClick = true
        if (this.reportID === null) {
          if (ability.can('create', 'reports')) {
            console.log('adding report')
            let proceedWithSave = true
            // Manual Validation Items
            // ********** Start Manual Validation **********
            // ********** End Manual Validation **********
            proceedWithSave = true
            console.log(this.reportData)
            console.log(JSON.parse(JSON.stringify(this.reportData)))
            if (proceedWithSave) {
              this.$refs.observer.validate().then(success => {
                if (success) {
                  this.preventDoubleClick = true
                  console.log('form validated')
                  console.log(this.reportData)
                  // Create a clean dataset with only the data being updated
                  const cleanReportData = JSON.parse(JSON.stringify(this.reportData))
                  // delete cleanReportData.XXXXXXXX
                  console.log(cleanReportData)
                  // Call backend
                  store.dispatch('app-reports/addReport', cleanReportData)
                    .then(response => {
                      console.log(response)
                      this.preventDoubleClick = false
                      // TODO Need to set the reportID based on the response
                      this.reportID = response.report_id
                      this.reportData.report_id = response.report_id
                      this.reportData.SK = response.SK
                      this.reportData.approved = response.approved
                      this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: 'Report Added',
                          icon: 'CheckIcon',
                          variant: 'success',
                        },
                      })
                    })
                    .catch(error => {
                      console.log(error)
                      this.preventDoubleClick = false
                      let theErrorStr = 'The following error(s) where received:\n'
                      if (typeof error.response.data.errors !== 'undefined') {
                        error.response.data.errors.forEach(item => {
                          theErrorStr += `${item.message}\n`
                        })
                      }
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Error Adding Report.',
                          icon: 'AlertTriangleIcon',
                          variant: 'danger',
                          text: theErrorStr,
                        },
                      })
                    })
                }
              })
            }
          }
        } else if (ability.can('update', 'reports')) {
          console.log('updating report')
          let proceedWithSave = true
          // Manual Validation Items
          // ********** Start Manual Validation **********
          // ********** End Manual Validation **********
          proceedWithSave = true
          console.log(this.reportData)
          console.log(JSON.parse(JSON.stringify(this.reportData)))
          if (proceedWithSave) {
            this.$refs.observer.validate().then(success => {
              if (success) {
                this.preventDoubleClick = true
                console.log('form validated')
                console.log(this.reportData)
                // Create a clean dataset with only the data being updated
                const cleanReportData = JSON.parse(JSON.stringify(this.reportData))
                // delete cleanReportData.abilities
                // delete cleanReportData.authorized_clients
                console.log(cleanReportData)
                // Call backend
                store.dispatch('app-reports/updateReport', cleanReportData)
                  .then(response => {
                    console.log(response)
                    this.preventDoubleClick = false
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: 'Report Updated',
                        icon: 'CheckIcon',
                        variant: 'success',
                      },
                    })
                  })
                  .catch(error => {
                    console.log(error)
                    this.preventDoubleClick = false
                    let theErrorStr = 'The following error(s) where received:\n'
                    if (typeof error.response.data.errors !== 'undefined') {
                      error.response.data.errors.forEach(item => {
                        theErrorStr += `${item.message}\n`
                      })
                    }
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: 'Error Updating Report.',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                        text: theErrorStr,
                      },
                    })
                  })
              }
            })
          }
        }
      }
    },
    async printReport() {
      const printContent = await this.generatePrintContent()
      console.log('printContent: ')
      console.log(printContent)
      const printWindow = window.open('', '_blank')
      printWindow.document.write(printContent)
      printWindow.document.close()
      printWindow.focus()
      setTimeout(() => {
        printWindow.print()
        printWindow.close()
      }, 2000)
    },
    async getImageForPrint() {
      // Check if there are any attachments
      if (!this.reportData.attachment_list.length) {
        return ''
      }

      // Prepare an array of promises for fetching the images
      const imagePromises = this.reportData.attachment_list.map(async item => {
        const result1 = await Storage.get(item.access_url, {
          download: false,
          bucket: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_BUCKET,
          region: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_REGION,
          identityPoolId: 'ca-central-1_Ao5uWjpYj',
          custom_header: async () => ({ Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }),
        })

        console.log(result1)
        return `<div><img src='${result1}'/><br><br></div>`
      })

      // Wait for all image promises to resolve
      const imageHTMLArray = await Promise.all(imagePromises)

      // Join the HTML snippets into a single string
      const imageHTML = imageHTMLArray.join('')

      console.log(imageHTML)
      return imageHTML
    },
    async generatePrintContent() {
      const imageHTML = await this.getImageForPrint()
      return `
      <html>
        <head>
        <title>Vehicle Inspection Report</title>
        <style>
            body {
            font-family: Arial, sans-serif;
            margin: 20px;
            }
            h2 {
            text-align: center;
            }
            .logo {
            width: 300px;
            margin-bottom: 20px;
            }
            .section {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 20px;
            }
            .section .label {
            width: 30%;
            font-weight: bold;
            }
            .section .value {
            width: 70%;
            }
            .section ul {
            list-style: none;
            padding: 0;
            }
            .section ul li {
            margin: 5px 0;
            }
        </style>
        </head>
        <body>
        <img src="/img/email-logo.png" class="logo" alt="Cancom">
        <h2>Vehicle Inspection Report</h2>
        <div class="section">
            <div class="label">Province:</div>
            <div class="value">${this.reportData.province}</div>
        </div>
        <div class="section">
            <div class="label">Type of Occurrence:</div>
            <div class="value">${this.reportData.occurrence_type}</div>
        </div>
        <div class="section">
            <div class="label">Client:</div>
            <div class="value">${this.getClientDisplayName(this.reportData.clientname)}</div>
        </div>
        <div class="section">
            <div class="label">Site:</div>
            <div class="value">${this.reportData.sitename}</div>
        </div>
        <div class="section">
            <div class="label">Site Address:</div>
            <div class="value">${this.reportData.site_address}</div>
        </div>
        <div class="section">
          <div class="label">Date:</div>
          <div class="value">${this.reportData.inspection_date}</div>
        </div>
        <div class="section">
          <div class="label">Licence Plate:</div>
          <div class="value">${this.reportData.licence_plate}</div>
        </div>
        <div class="section">
          <div class="label">Vehicle Make Model:</div>
          <div class="value">${this.reportData.make_model}</div>
        </div>
        <div class="section">
          <div class="label">Vehicle Year:</div>
          <div class="value">${this.reportData.vehicle_year}</div>
        </div>
        <div class="section">
          <div class="label">Purchased Gas:</div>
          <div class="value">${this.reportData.is_purchase_gas}</div>
        </div>
        <div class="section">
          <div class="label">Purchase Gas Price$:</div>
          <div class="value">${this.reportData.gas_price}</div>
        </div>
        <div class="section">
          <div class="label">Purchase Gas Litres:</div>
          <div class="value">${this.reportData.gas_litres}</div>
        </div>
        <div class="section">
          <div class="label">Purchased Electricity:</div>
          <div class="value">${this.reportData.is_purchase_electricity}</div>
        </div>
        <div class="section">
          <div class="label">Charge Fee:</div>
          <div class="value">${this.reportData.charge_fee}</div>
        </div>
        <div class="section">
          <div class="label">Charge Percent:</div>
          <div class="value">${this.reportData.charge_percent}</div>
        </div>
        <div class="section">
          <div class="label">Last Oil change:</div>
          <div class="value">${this.reportData.last_oil_change}</div>
        </div>
        <div class="section">
          <div class="label">Next Oil Change:</div>
          <div class="value">${this.reportData.next_oil_change}</div>
        </div>
        <div class="section">
          <div class="label">Starting KM:</div>
          <div class="value">${this.reportData.starting_km}</div>
        </div>
        <div class="section">
          <div class="label">Ending KM:</div>
          <div class="value">${this.reportData.ending_km}</div>
        </div>
        <div class="section">
          <div class="label">Leaks:</div>
          <div class="value">${this.reportData.leaks}</div>
        </div>
        <div class="section">
          <div class="label">Dents/Scratches:</div>
          <div class="value">${this.reportData.dents_scratches}</div>
        </div>
        <div class="section">
          <div class="label">Tire Pressure/Tred:</div>
          <div class="value">${this.reportData.tire_pressure}</div>
        </div>
        <div class="section">
          <div class="label">Decals/Lights:</div>
          <div class="value">${this.reportData.decals_lights}</div>
        </div>
        <div class="section">
          <div class="label">Upholstery (Floor, Seats, Doors, Dashboard, Roof):</div>
          <div class="value">${this.reportData.upholstery}</div>
        </div>
        <div class="section">
          <div class="label">Seat belts (Working):</div>
          <div class="value">${this.reportData.seatbelts}</div>
        </div>
        <div class="section">
          <div class="label">Folding Seats (Working):</div>
          <div class="value">${this.reportData.folding_seats}</div>
        </div>
        <div class="section">
          <div class="label">Headrests (Working):</div>
          <div class="value">${this.reportData.headrests}</div>
        </div>
        <div class="section">
          <div class="label">Glowlights (Dashboard):</div>
          <div class="value">${this.reportData.glow_lights}</div>
        </div>
        <div class="section">
          <div class="label">Interior Lights:</div>
          <div class="value">${this.reportData.interior_lights}</div>
        </div>
        <div class="section">
          <div class="label">Radio / Volume (All Outputs):</div>
          <div class="value">${this.reportData.radio}</div>
        </div>
        <div class="section">
          <div class="label">Window (Driver controls / Passenger controls):</div>
          <div class="value">${this.reportData.windows}</div>
        </div>
        <div class="section">
          <div class="label">Mirrors:</div>
          <div class="value">${this.reportData.mirrors}</div>
        </div>
        <div class="section">
          <div class="label">Air Controls:</div>
          <div class="value">${this.reportData.air_controls}</div>
        </div>
        <div class="section">
          <div class="label">Wipers:</div>
          <div class="value">${this.reportData.wipers}</div>
        </div>
        <div class="section">
          <div class="label">Lights / High-beams / Signals / Brakes:</div>
          <div class="value">${this.reportData.signal_lights}</div>
        </div>
        <div class="section">
          <div class="label">Electric Seats (All Positions):</div>
          <div class="value">${this.reportData.electric_seats}</div>
        </div>
        <div class="section">
          <div class="label">Locks (Driver / Individual):</div>
          <div class="value">${this.reportData.door_locks}</div>
        </div>
        <div class="section">
          <div class="label">Horn:</div>
          <div class="value">${this.reportData.horn}</div>
        </div>
        <div class="section">
          <div class="label">Fluids (Windshield, Power steering, Oil, Brake fluid):</div>
          <div class="value">${this.reportData.fluids}</div>
        </div>
        <div class="section">
          <div class="label">Belts:</div>
          <div class="value">${this.reportData.belts}</div>
        </div>
        <div class="section">
          <div class="label">Filtres:</div>
          <div class="value">${this.reportData.filtres}</div>
        </div>
        <div class="section">
          <div class="label">Battery Terminals:</div>
          <div class="value">${this.reportData.battery}</div>
        </div>
        <div class="section">
          <div class="label">Notes:</div>
          <div class="value">${this.reportData.notes}</div>
        </div>
        <div class="section">
          ${imageHTML}
        </div>
        </body>
    </html>
    `
    },
    handleApprovalToggle() {
      console.log('handleApprovalToggle')
      if (this.reportData.approved) {
        this.reportData.approved = false
      } else {
        this.reportData.approved = true
      }
      this.preventDoubleClick = false
      this.validateForm()
    },
    getClientDisplayName(clientname) {
      const client = this.clientOptions.find(option => option.value === clientname)
      return client ? client.label : ''
    },
    getResultDisplay(result) {
      const resultOption = this.resultOptions.find(option => option.value === result)
      return resultOption ? resultOption.text : ''
    },
    // Camera / Images Methods
    openCamera() {
      // Trigger the file input click event
      this.$refs.cameraInput.click()
    },
    openImageSelector() {
      // Trigger the file input click event
      this.$refs.imageInput.click()
    },
    handleImageChange(event) {
      const file = event.target.files[0]
      const supportedExtensions = ['jpg', 'jpeg', 'png', 'tiff', 'gif', 'heif']
      if (file) {
        const parts = file.name.split('.')
        if (parts.length === 1) {
          throw new Error('File not valid')
        }
        const fileExtension = parts.pop().toLowerCase()
        if (supportedExtensions.includes(fileExtension)) {
          const theFileName = `${this.reportData.report_id}_${moment(Date()).format('YYYYMMDDTHH-mm-ss')}.jpg`
          const theFullFileName = `reports/${this.reportData.report_type}/${theFileName}`
          this.preventDoubleClick = true
          Storage.put(theFullFileName, file, {
            bucket: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_BUCKET,
            region: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_REGION,
            identityPoolId: 'ca-central-1_Ao5uWjpYj',
            custom_header: async () => ({ Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }),
          })
            .then(result => {
              this.reportData.attachment_list.push({
                filename: theFileName,
                access_url: result.key,
                original_ext: fileExtension,
              })
              this.preventDoubleClick = false
              this.validateForm()
            })
            .catch(err => {
              console.log(err)
              this.preventDoubleClick = false
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error Uploading File.',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Invalid File Type. Must be JPG, TIFF, PNG, or GIF.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
    deleteImage(item) {
      console.log('deleteimage')
      console.log(item)
      this.preventDoubleClick = true
      Storage.remove(item.access_url, {
        bucket: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_BUCKET,
        region: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_REGION,
        identityPoolId: 'ca-central-1_Ao5uWjpYj',
        custom_header: async () => ({ Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }),
      })
        .then(result => {
          console.log(result)
          console.log(this.reportData)
          // Update the report data deleting the item from the attachment list
          this.reportData.attachment_list.forEach((item1, index) => {
            if (item1.access_url === item.access_url) {
              this.reportData.attachment_list.splice(index, 1)
            }
          })
          this.preventDoubleClick = false
          this.validateForm()
        })
        .catch(err => {
          console.log(err)
          this.preventDoubleClick = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error Deleting File.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    // File Attachment Methods
    openFileSelector() {
      // Trigger the file input click event
      this.$refs.fileInput.click()
    },
    handleFileChange(event) {
      const file = event.target.files[0]
      if (file) {
        const parts = file.name.split('.')
        if (parts.length === 1) {
          throw new Error('File not valid')
        }
        const fileExtension = parts.pop().toLowerCase()
        const theFileName = `${this.reportData.report_id}_${moment(Date()).format('YYYYMMDDTHH-mm-ss')}.${fileExtension}`
        const theFullFileName = `reports/${this.reportData.report_type}/${theFileName}`
        this.preventDoubleClick = true
        Storage.put(theFullFileName, file, {
          bucket: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_BUCKET,
          region: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_REGION,
          identityPoolId: 'ca-central-1_Ao5uWjpYj',
          custom_header: async () => ({ Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }),
        })
          .then(result => {
            this.reportData.attachment_list.push({
              filename: theFileName,
              access_url: result.key,
              original_ext: fileExtension,
            })
            this.preventDoubleClick = false
            this.validateForm()
          })
          .catch(err => {
            console.log(err)
            this.preventDoubleClick = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error Uploading File.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    },
    downloadFile(item) {
      console.log('downloadFile')
      console.log(item)
      this.preventDoubleClick = true
      Storage.get(item.access_url, {
        bucket: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_BUCKET,
        region: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_REGION,
        identityPoolId: 'ca-central-1_Ao5uWjpYj',
        custom_header: async () => ({ Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }),
      })
        .then(url => {
          const link = document.createElement('a')
          link.href = url
          link.download = item.filename || 'download' // you can set a default filename if item.filename is not available
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          this.preventDoubleClick = false
        })
        .catch(err => {
          console.log(err)
          this.preventDoubleClick = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error Downloading File.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    deleteFile(item) {
      console.log('deletefile')
      console.log(item)
      this.preventDoubleClick = true
      Storage.remove(item.access_url, {
        bucket: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_BUCKET,
        region: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_REGION,
        identityPoolId: 'ca-central-1_Ao5uWjpYj',
        custom_header: async () => ({ Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }),
      })
        .then(result => {
          console.log(result)
          console.log(this.reportData)
          // Update the report data deleting the item from the attachment list
          this.reportData.attachment_list.forEach((item1, index) => {
            if (item1.access_url === item.access_url) {
              this.reportData.attachment_list.splice(index, 1)
            }
          })
          this.preventDoubleClick = false
          this.validateForm()
        })
        .catch(err => {
          console.log(err)
          this.preventDoubleClick = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error Deleting File.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style>
#fileInput {
  display: none;
}
.radio-spacing .custom-control {
    margin-top: 10px; /* Adjust this value to increase or decrease spacing */
}
</style>
